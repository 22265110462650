import React from "react";

class AxiosLibrary extends React.Component {
  state = {};
  render() {
    return <div>Axios Library</div>;
  }
}

export default AxiosLibrary;
