import React from "react";
import AxiosLibrary from "./AxiosLibrary";

class LibrariesMaster extends React.Component {
  state = {};
  render() {
    return <AxiosLibrary />;
  }
}

export default LibrariesMaster;
