import React from "react";

const MiniBlogArticle = () => {
  return (
    <div>
      <p>
        <b>Article:</b> React is better than Angular... Like way better You need
        Proof? I knew a guy who said he was gonna learn Angular... Next day a
        vending machine fell on him... True story
      </p>
    </div>
  );
};

export default MiniBlogArticle;

export const MiniBlogArticleCode = `
import React from "react";

const MiniBlogArticle = () => {
  return (
    <div>
      <p>
        <b>Article:</b> React is better than Angular... Like way better You need
        Proof? I knew a guy who said he was gonna learn Angular... Next day a
        vending machine fell on him... True story
      </p>
    </div>
  );
};

export default MiniBlogArticle;
`.trim();
